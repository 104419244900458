
import { Component, Prop, Vue } from "vue-property-decorator";
import LogoSingle from "@/components/global/LogoSingle.vue";
import LoginForm from "@/components/forms/LoginForm.vue";

enum Accion {
  Confirmar = "confirmar_reserva",
  Anular = "cancelar_reserva"
}

@Component({
  components: {
    LogoSingle,
    LoginForm
  }
})
export default class Login extends Vue {
  private base_bucket = process.env.VUE_APP_AWS_ASSETS;
  private idComuna = process.env.VUE_APP_ID_COMUNA;
  private activeRedes: any = "";
  private appStore: any = "";
  private android: any = "";
  
  accion: Accion | null = null;
  titulo = "Confirmar Reserva"
  accion_dialog = "confirmada"
  success_dialog = false;

  private accionReserva() {

    var axios_call = "";
    var axios_params = {};

    if(["confirmar_reserva", "anular_reserva"].includes(this.$router.currentRoute.name as string))
        this.$store.state.ticket.step = 0;

    switch(this.$router.currentRoute.name){
      case "confirmar_reserva":
        this.accion = Accion.Confirmar;
        axios_call = "reservas/confirmar-reserva";
        axios_params = { token: this.$route.params.token };
        break;
      case "anular_reserva":
        this.accion = Accion.Anular;
        this.titulo = "Anular Reserva"
        this.accion_dialog = "anulada"
        axios_call = "reservas/cancelar-reserva";
        axios_params = { token: this.$route.params.token };
        break;
      default:
        return;
    }

    this.$axios.patch(axios_call, null, {
      params: axios_params,
    }).then(response => {
      this.success_dialog = true;
      setTimeout(() => {
        this.success_dialog = false;
        this.redirectHome()
      }, 5000);
    }).catch(error => {
      var message = "";
      var message_action = this.accion == Accion.Confirmar ? "confirmando" : "anulando";
      var enviar_mensaje = true;
      switch(error.response.data.error.message){
        case "expired_token":
          message = "El tiempo de gestión de esta reserva ha finalizado";
          break;
        case "consumed_token":
          message = "Usted ya ha gestionado esta reserva";
          break;
        case "token_no_data":
        case "invalid_token":
        case "reserva_no_data":
          enviar_mensaje = false;
          break;
        default:
          message = "Hubo un problema " + message_action + " la reserva";
      }
      if(enviar_mensaje){
        this.$q.notify({
          message: message,
          color: "principal",
          position: "top",
          timeout: 5000
        });
      }
    })
  }

  redirectHome() {
    window.location.href = process.env.VUE_APP_FRONT_OFFICE_URL as string
  }

  created() {
    this.activeRedes = process.env.VUE_APP_RRSS_LOGIN;
    this.android = process.env.VUE_APP_RRSS_ANDROID;
    this.appStore = process.env.VUE_APP_RRSS_APPSTORE;
  }
  
  mounted() {
    this.accionReserva()
  }

  private routerGo(name: any) {
    if (name && name != "") {
      if (this.$router.currentRoute.name == name) {
        this.$router.go(0);
      } else {
        this.$router.push({ name: name }).catch(err => {
          this.$q.notify({
            message: "Ha ocurrido un error",
            color: "principal",
            position: "top",
            timeout: 5000
          });
        });
      }
    }
  }

  private irGooglePlay(url: any) {
    window.open(url);
  }

  private irAppStore(url: any) {
    window.open(url);
  }
}
